import i18next from "i18next";
import moment from "moment";
import { setLocale } from "yup";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// resources
import enTranslations from "../locales/en/";
import fiTranslations from "../locales/fi/";
import plTranslations from "../locales/pl/";
import etTranslations from "../locales/et/";
import svTranslations from "../locales/sv/";

// utils
import { change } from "redux/actions/language";
import { LanguageEnum } from "consts";
import { set } from "./local-storage";
import store from "redux/store";

const DEFAULT = LanguageEnum.EN;

// init i18next with all the options
i18next.use(initReactI18next); // if not using I18nextProvider
i18next.use(LanguageDetector);
i18next.init({
  fallbackLng: "en",

  interpolation: {
    escapeValue: false, // not actualy needed for react
  },

  resources: {
    en: {
      translations: enTranslations,
    },
    fi: {
      translations: fiTranslations,
    },
    pl: {
      translations: plTranslations,
    },
    et: {
      translations: etTranslations,
    },
    sv: {
      translations: svTranslations,
    },
  },

  // have a common namespace used around the app
  ns: ["translations"],
  defaultNS: "translations",

  // react i18next special options (optional)
  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
  },
});

// this is used in some places, keep it alive
export { i18next };

export const getLanguages = () => {
  const keys = Object.keys(i18next.services.resourceStore.data);
  const languages: LanguageEnum[] = [];
  for (const language of keys) {
    languages.push(language as LanguageEnum);
  }
  return languages;
};

// changes the language, defaults to "en" if no attribute given
export const changeLanguage = async (language = DEFAULT) => {
  i18next.changeLanguage(language);
  // change locale in moment
  let localeForMoment = language as string;
  if (localeForMoment === "en") {
    localeForMoment = "en-gb"; // user proper european version instead of US crap
  }
  moment.locale(localeForMoment);

  // change locale in yup
  const yup: any = i18next.t("yup", { returnObjects: true }); // actually returns object though definition says string
  setLocale(yup);

  // dispatch to redux
  change(language);

  // remember language
  set("language", language);
};

export const hasTranslation = (key: string) => {
  const translation = i18next.t(key);
  const result = translation !== key;
  return result;
};

// use this to translate
export const translate = (key: string, optionals?: any) => {
  return i18next.t(key, optionals);
};

// use this to translate to other langauges
export const translateTo = (language: LanguageEnum, key: string, optionals?: any) => {
  const translateFn = i18next.getFixedT(language);
  return translateFn(key, optionals);
};

// gets language specified from the given object
export const translateFrom = (object: TranslatedStringModel, language?: LanguageEnum) => {
  if (!object) {
    return object;
  }

  const languageState = store.getState().language;
  const defaultLanguage = i18next.languages[0];

  // use requested language or the languageState
  const targetLanguage = language ? language : languageState;

  // if exists, you that
  if (object[targetLanguage] !== undefined) {
    return object[targetLanguage];
  }

  // object did not have language specified, fallback to default, or ""
  return object[defaultLanguage] || "";
};

export interface TranslatedStringModel {
  [language: string]: string;
}
